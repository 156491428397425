<template>
  <div>
    <div class="table_common" v-show="!companyCreditShow">
      <div class="search_flex_box flex_center_between_box">
        <div class="search_condition">
          <el-form ref="form" :model="form">
            <el-form-item label="订单编号:" class="financing_number">
              <el-input v-model="form.id" @input="searchInput" maxlength="20"><template
                  slot="prepend">OD</template></el-input>
            </el-form-item>
            <el-form-item label="创建时间:">
              <el-date-picker v-model="form.year" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至"
                start-placeholder="开始日期" start-class end-placeholder="结束日期" format="yyyy-MM-dd "
                value-format="yyyy-MM-dd " :editable="false">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="search_boxs flex_center_between_box">
          <div class="search_button_box">
            <i class="iconfont icon-10sousuo" @click="cxfwlb()"></i>
          </div>
          <div class="reset_button_box">
            <i class="iconfont icon-shuaxin" @click="shuaxin()"></i>
          </div>
        </div>
      </div>
      <div class="table_box">
        <!-- <div class="anniudiv" v-if="tableActiveIndex===2">
                   
                </div> -->
        <div class="anniudiv">
          <el-button round @click="sqfw()">申请服务</el-button>
        </div>
        <tab :list-data="tabList" :active-index.sync="tableActiveIndex" @tabChange="tableChange" />
        <div class="table_box_bottom table_tab_div">
          <el-table v-if="tableActiveIndex === 2" :data="tableData2" stripe border style="width: 100%">
            <template slot="empty">
              <NoData />
            </template>
            <el-table-column label="订单编号" prop="orderNum" align="center" :width="OrderNoWidth" show-overflow-tooltip />
            <el-table-column label="创建时间" prop="createDate" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="主体名称" prop="subjectName" align="left" header-align="center" :width="nameWidth"
              border />
            <el-table-column label="评级结果" prop="ratResult" align="center">
              <!-- <template slot-scope="scope">
                                <div class="link">{{scope.row.ratResult}}</div>
                            </template> -->
            </el-table-column>
            <el-table-column label="评级类型" prop="ratType" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.ratType == 1">公司评级</div>
                <div v-else-if="scope.row.ratType == 2">发票评级</div>
              </template>
            </el-table-column>
            <el-table-column label="查看报告" align="center">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.orderState != 3" class="item_color_money_complete" effect="light" content="下载报告"
                  placement="top">
                  <i class="iconfont icon-xiazai1" style="color: #E1E7EA"></i>
                </el-tooltip>
                <el-tooltip v-else-if="scope.row.orderState == 3" class="item_color_money_complete" effect="light"
                  content="下载报告" placement="top">
                  <i class="iconfont icon-xiazai1" style="color:#339C9B" @click="downlod(scope.row.id)"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="orderState" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.orderState == 1">待办</div>
                <div v-else-if="scope.row.orderState == 2">进行中</div>
                <div v-else-if="scope.row.orderState == 3">已完成</div>
                <div v-else-if="scope.row.orderState == 4">失败</div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <Page :totalFont=true :total=page1.total @onPageChange="onPageChange"></Page> -->
          <el-table v-if="tableActiveIndex === 1" :key=tableActiveIndex :data="tableData1" stripe border
            style="width: 100%">
            <template slot="empty">
              <NoData />
            </template>
            <el-table-column label="订单编号" prop="orderNum" align="center" :width="OrderNoWidth" show-overflow-tooltip />
            <el-table-column label="主体名称" prop="subjectName" align="left" header-align="center" :width="nameWidth"
              border />
            <el-table-column label="监踪周期" align="center" prop="trackCycle" />
            <el-table-column label="监踪频次" prop="trackFrequency" align="center" />
            <el-table-column label="最后更新时间" prop="lastUpdateTime" align="center" />
            <el-table-column label="查看报告" align="center">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.orderState != 3" class="item_color_money_complete" effect="light" content="下载报告"
                  placement="top">
                  <i class="iconfont icon-xiazai1" style="color: #E1E7EA"></i>
                </el-tooltip>
                <el-tooltip v-else-if="scope.row.orderState == 3" class="item_color_money_complete" effect="light"
                  content="下载报告" placement="top">
                  <i class="iconfont icon-xiazai1" style="color:#339C9B" @click="downlod(scope.row.id)"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="orderState" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.orderState == 1">待办</div>
                <div v-else-if="scope.row.orderState == 2">进行中</div>
                <div v-else-if="scope.row.orderState == 3">已完成</div>
                <div v-else-if="scope.row.orderState == 4">失败</div>
              </template>
            </el-table-column>
          </el-table>
          <el-table v-if="tableActiveIndex === 0" :key=tableActiveIndex :data="tableData" stripe border
            style="width: 100%">
            <template slot="empty">
              <NoData />
            </template>
            <el-table-column label="订单编号" prop="orderNum" align="center" :width="OrderNoWidth" show-overflow-tooltip />
            <el-table-column label="创建时间" prop="createDate" align="center" show-overflow-tooltip />
            <el-table-column label="主体名称" prop="subjectName" align="left" header-align="center" :width="nameWidth"
              border />
            <el-table-column label="认证结果" prop="verificationResult" align="center" />
            <el-table-column label="查看报告" align="center">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.orderState != 3" class="item_color_money_complete" effect="light" content="下载报告"
                  placement="top">
                  <i class="iconfont icon-xiazai1" style="color: #E1E7EA"></i>
                </el-tooltip>
                <el-tooltip v-else-if="scope.row.orderState == 3" class="item_color_money_complete" effect="light"
                  content="下载报告" placement="top">
                  <i class="iconfont icon-xiazai1" style="color:#339C9B" @click="downlod(scope.row.id)"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="start" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.orderState == 1">待办</div>
                <div v-else-if="scope.row.orderState == 2">进行中</div>
                <div v-else-if="scope.row.orderState == 3">已完成</div>
                <div v-else-if="scope.row.orderState == 4">失败</div>
              </template>
            </el-table-column>
          </el-table>
          <el-table v-if="tableActiveIndex === 3" :key=tableActiveIndex :data="tableData3" stripe border
            style="width: 100%">
            <template slot="empty">
              <NoData />
            </template>
            <el-table-column label="订单编号" prop="orderNum" align="center" :width="OrderNoWidth" show-overflow-tooltip />
            <el-table-column label="创建时间" prop="createDate" align="center" show-overflow-tooltip />
            <el-table-column label="主体名称" prop="subjectName" align="left" header-align="center" :width="nameWidth"
              border />
            <el-table-column label="催款结果" prop="collectionResult" align="center" />
            <el-table-column label="逾期天数" prop="overdueDay" align="center" />
            <el-table-column label="查看报告" align="center">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.orderState != 3" class="item_color_money_complete" effect="light" content="下载报告"
                  placement="top">
                  <i class="iconfont icon-xiazai1" style="color: #E1E7EA"></i>
                </el-tooltip>
                <el-tooltip v-else-if="scope.row.orderState == 3" class="item_color_money_complete" effect="light"
                  content="下载报告" placement="top">
                  <i class="iconfont icon-xiazai1" style="color:#339C9B" @click="downlod(scope.row.id)"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="start" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.orderState == 1">待办</div>
                <div v-else-if="scope.row.orderState == 2">进行中</div>
                <div v-else-if="scope.row.orderState == 3">已完成</div>
                <div v-else-if="scope.row.orderState == 4">失败</div>
              </template>
            </el-table-column>
          </el-table>
          <Page :totalFont="true" :currentPage="currentPage"
            :total="tableActiveIndex == 3 ? page3.total : tableActiveIndex == 2 ? page4.total : tableActiveIndex == 0 ? page1.total : page2.total"
            @onPageChange="onPageChangeList"></Page>
        </div>
      </div>
    </div>
    <!-- 监踪服务 -->
    <el-dialog title="申请服务" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose"
      class="legalaid" id="jzfw">
      <el-form :key="formFirst" label-width="100px" class="f2" :model="form2" ref="form2" :rules="rulesform2">
        <el-form-item label="公司名称:" class="retained_profits2" prop="subjectName">
          <el-autocomplete class="inline-input" placeholder="" v-model="form2.subjectName"
            :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect" maxlength="40">
          </el-autocomplete>
          <!-- <el-input class="mc" v-model="form2.subjectName" id="mc" ></el-input> -->
        </el-form-item>
        <el-form-item label="监踪周期:" class="retained_profits2" prop="trackCycle">
          <el-input class="mc" v-model="form2.trackCycle" @input="formInput1" maxlength="5"></el-input>
        </el-form-item>
        <el-form-item label="监踪频次:" class="retained_profits2" prop="trackFrequency">
          <el-input class="mc" v-model="form2.trackFrequency" @input="formInput2" maxlength="5"></el-input>
        </el-form-item>
        <div class="anniudk">
          <div class="quxiaok" id="legalaidquxiao">
            <el-button type="info" round class="qiuxiao" @click="dialogVisible = false">取消</el-button>
          </div>
          <div class="tijiaok" id="legalaidtijiao">
            <el-button type="success" round class="tijiao" @click="legalaidpresent(2)">提交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 认证服务 -->
    <el-dialog title="申请服务" :visible.sync="dialogVisible2" :close-on-click-modal="false" :before-close="handleClose"
      class="legalaid" id="wdrz">
      <el-form :key="formFirst1" label-width="100px" class="f2 special_input" :model="form2" ref="form2"
        :rules="rulesform2">
        <el-form-item label="公司名称:" class="retained_profits2 " prop="subjectName">
          <el-autocomplete class="inline-input retained_profits2_special" placeholder="" v-model="form2.subjectName"
            :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect" id="mc" maxlength="40">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="所在省份:" prop="city">
          <el-select v-model="form2.city" placeholder="">
            <el-option v-for="item in  addressList1" :key="item.index" :label="item.value" :value="item.codeState" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所在城市:" class="retained_profits2" prop="city">
                <el-input class="mc" v-model="form2.city"></el-input>
            </el-form-item> -->
        <el-form-item label="详细地址:" class="retained_profits2" prop="detailedAddress">
          <el-input class="mc" v-model="form2.detailedAddress" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item label="是否授权:" id="rz" prop="authorizedState">
          <el-radio-group v-model="form2.authorizedState">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传授权书:" class="retained_profits5" id="scbg" prop="authorization" ref="uploadFormItem">
          <el-upload :on-change="uploadImg" action="fakeaction1" list-type="picture" :http-request="handleUploadFile1"
            ref="Uploadthereport" v-model="form2.authorization" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG,.pdf">
            <div class="upload_box">
              <i class="el-icon-plus"></i>
            </div>
            <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
          </el-upload>
        </el-form-item>
        <div class="anniudk">
          <div class="quxiaok" id="legalaidquxiao">
            <el-button type="info" round class="qiuxiao" @click="dialogVisible2 = false">取消</el-button>
          </div>
          <div class="tijiaok" id="legalaidtijiao">
            <el-button type="success" round class="tijiao" @click="legalaidpresent(1)">提交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 风控服务 -->
    <el-dialog title="申请服务" :visible.sync="dialogVisible3" :close-on-click-modal="false" :before-close="handleClose"
      class="legalaid" id="flyz">
      <el-form :key="formFirst2" label-width="100px" class="f2 add_invoice_messages" :model="form2" ref="form2"
        :rules="rulesform2">
        <el-form-item label="公司名称:" class="retained_profits2" prop="subjectName">
          <el-autocomplete class="inline-input" placeholder="" v-model="form2.subjectName"
            :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect" id="mc" maxlength="40">
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="标的金额:" class="retained_profits2" prop="underlyingAmount">
          <el-input class="mc" v-model.number="form2.underlyingAmount" @blur="accountBlur()" @input="codeInput"
            maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="应收日期:" class="retained_profits2" prop="receivableDate"
          :class="exceptArrivalClass ? 'exceptArrivalClass' : ''">
          <el-date-picker class="mc retained_profits2_specials" v-model="form2.receivableDate"
            prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            @change="exceptArrivalChange1()" :editable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="逾期天数:" class="retained_profits2" prop="overdueDay">
          <el-input class="mc" v-model.number="form2.overdueDay" @input="codeInput1" maxlength="5"></el-input>
        </el-form-item>
        <div class="anniudk">
          <div class="quxiaok" id="legalaidquxiao">
            <el-button type="info" round class="qiuxiao" @click="dialogVisible3 = false">取消</el-button>
          </div>
          <div class="tijiaok" id="legalaidtijiao">
            <el-button type="success" round class="tijiao" @click="legalaidpresent()">提交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <div v-show="companyCreditShow">
      <div class="second_return_icon margin_20 tb">
        <i class="iconfont icon-fanhui" @click="returnCompanyButton()"></i>
      </div>
      <Company></Company>
    </div>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import NoData from '@/components/noData'
import Page from '@/components/page'
import Tab from '@/components/tab'
import Company from '../../supplier/components/companyDetail2.vue'
import { findSupplierMyServiceList, applyService, uploads } from "@/api/supplier.js";
import { findCodeInfoByKey } from "@/api/investor.js";
import { getCompanyNameList, downloadExport } from "@/api/api.js";
import loginVue from '../../../dashboard/components/login.vue';
export default {
  mixins: [mixins1],
  components: { Page, Tab, NoData, Company },
  data() {
    const valTrackCycle = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入监踪周期'))
      }
      // else if (/^[0-9]*$/.test(val) == false) {
      //   return cb(new Error("请输入5位数字"));
      // }
      return cb()
    }
    const valTrackFrequency = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入监踪频次'))
      }
      // else if (/^[0-9]*$/.test(val) == false) {
      //   return cb(new Error("请输入5位数字"));
      // }
      return cb()
    }
    const valOverdueDay = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入逾期天数'))
      } else if (/^[0-9]*$/.test(val) == false) {
        return cb(new Error("请输入5位数字"));
      }
      return cb()
    }
    const valUnderlyingAmount= (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入标的金额'))
      } else if (val==0) {
        return cb(new Error("标的金额不能那为0"));
      }
      return cb()
    }
    return {
      rulesform2: {
        subjectName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        trackCycle: [
          { required: true, validator: valTrackCycle, trigger: 'blur' }
        ],
        trackFrequency: [
          { required: true, validator: valTrackFrequency, trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入所在城市', trigger: 'blur' }
        ],
        detailedAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        authorizedState: [
          { required: true, message: '请选择是否授权', trigger: 'change' }
        ],
        authorization: [
          { required: true, message: '请上传授权书', trigger: 'blur' }
        ],
        underlyingAmount: [
        { required: true, validator: valUnderlyingAmount, trigger: 'blur' }
        ],
        receivableDate: [
          { required: true, message: '选择应收日期', trigger: 'blur' }
        ],
        overdueDay: [
          { required: true, validator: valOverdueDay, trigger: 'blur' }
        ],
      },
      form: {
        id: '',
        year: '',
        startTime: "",
        endTime: "",
        orderType: ""
      },
      tableActiveIndex: 0,
      tabList: [
        {
          name: '认证服务'
        },
        {
          name: '监踪服务'
        },
        {
          name: '信评服务'
        },
        {
          name: '风控服务'
        }
      ],
      tableData: [
      ],
      tableData1: [
      ],
      tableData2: [
      ],
      tableData3: [
      ],
      data: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      form2: {
        subjectName: "",
        trackCycle: "",
        trackFrequency: "",
        authorization: "",
        authorizedState: "",
        city: "",
        detailedAddress: "",
        underlyingAmount: "",
        receivableDate: "",
        overdueDay: "",
        orderType: ""
      },
      tiaoshu: "",
      page1: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      page2: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      page3: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      page4: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      currentPage: 1,
      dataname: [],
      addressList1: [],
      companyCreditShow: false,
      exceptArrivalClass: false,
      formFirst: 0,
      formFirst1: 0,
      formFirst2: 0
    }
  },
  created() {
    this.tableWidth()
    // this.cxfwlb();
    this.cx();
    this.ficationList1()
  },
  mounted() {
    // this.tc();
  },
  methods: {
    formInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.form2.trackCycle = val
    },
    formInput2(val) {
      val = val.replace(/[^\d]/g, '')
      this.form2.trackFrequency = val
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.id = val
    },
    codeInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.form2.underlyingAmount = val
    },
    codeInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.form2.overdueDay = val
    },
    uploadImg(file) {
      this.$nextTick(() => {
        this.$refs['uploadFormItem'].clearValidate()
      })
    },
    returnCompanyButton() {
      this.companyCreditShow = false
    },
    // 弹框关闭按钮
    handleClose() {
      this.dialogVisible = false
      this.dialogVisible3 = false
      this.dialogVisible2 = false
    },
    async downlod(id) {
      let res = await downloadExport('id=' + id)
      // console.log(res.data[0])
      // for (let i = 0;i<this.upLoadFileNumber.length;i++) {
      // window.location.href="/file/合同.docx"
      // }
      // window.open(this.buildForm[name]);
      // window.open("https://wenku.baidu.com/view/24c80d20793e0912a21614791711cc7930b77874.html");
      // console.log(this.upLoadFileName)
      res.data.url.forEach((item, index) => {
        console.log(item)
        var link = document.createElement("a");
        link.setAttribute("download", res.data.urlName);
        // link.href = this.jpg + item;
        link.href = item;
        link.click();
      });
      // link.remove();
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 23)
      this.addressList1 = res.data
    },
    exceptArrivalChange1() {
      if (this.form2.receivableDate) {
        this.exceptArrivalClass = true
      }
      else {
        this.exceptArrivalClass = false
      }
    },
    accountBlur() {
      this.form2.underlyingAmount = this.filter(this.form2.underlyingAmount)
    },
    async onPageChangeList(val) {
      if (this.tableActiveIndex == 2) {
        this.page1.pageNo = val
        this.currentPage = this.page1.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData2 = data.data.records
      } else if (this.tableActiveIndex == 1) {
        this.page2.pageNo = val
        this.currentPage = this.page2.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page2.pageNo + '&pageSize=' + this.page2.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData1 = data.data.records
      } else if (this.tableActiveIndex == 0) {
        this.page3.pageNo = val
        this.currentPage = this.page3.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page3.pageNo + '&pageSize=' + this.page3.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData = data.data.records
      } else if (this.tableActiveIndex == 3) {
        this.page4.pageNo = val
        this.currentPage = this.page4.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page4.pageNo + '&pageSize=' + this.page4.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData3 = data.data.records
      }
    },
    handleUploadFile1(params) {
      if (!this.form2.authorization) {
        const file1 = params.file
        let form = new FormData();
        form.append("files", file1);
        uploads(form).then(res => {
          this.form2.authorization = res[0]
        })
      }
    },
    tableChange(index) {
      if (index == 0) {
        this.form.orderType = 4
        //   this.tiaoshu=this.tableData.length
      } else if (index == 1) {
        this.form.orderType = 2
        //    this.tiaoshu=this.tableData1.length
      } else if (index == 2) {
        this.form.orderType = 1
        //    this.tiaoshu=this.tableData2.length
      } else if (index == 3) {
        this.form.orderType = 3
        //   this.tiaoshu=this.tableData3.length
      }
      this.tableActiveIndex = index
      this.cxfwlb();
    },
    sqfw() {
      if (this.tableActiveIndex === 1) {
        this.formFirst++
        this.dialogVisible = true
      } else if (this.tableActiveIndex === 0) {
        this.formFirst1++
        this.dialogVisible2 = true
      } else if (this.tableActiveIndex === 3) {
        this.formFirst2++
        this.dialogVisible3 = true
      } else if (this.tableActiveIndex === 2) {
        this.companyCreditShow = true
      }
      this.$refs.form2.resetFields()
      this.form2 = {}

    },
    async cxfwlb() {
      if (this.form.year != null) {
        if (this.form.year[0]) {
          this.form.startTime = this.form.year.slice(',')[0]
          this.form.endTime = this.form.year.slice(',')[1]
        } else {
          this.form.startTime = ""
          this.form.endTime = ""
        }
      } else {
        this.form.startTime = ""
        this.form.endTime = ""
      }
      if (this.form.orderType == 4) {
        this.page1.pageNo = 1
        this.currentPage = this.page1.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData = data.data.records
        this.page1.total = data.data.total
      } else if (this.form.orderType == 2) {
        this.page2.pageNo = 1
        this.currentPage = this.page2.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page2.pageNo + '&pageSize=' + this.page2.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData1 = data.data.records
        this.page2.total = data.data.total
      } else if (this.form.orderType == 1) {
        this.page3.pageNo = 1
        this.currentPage = this.page3.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page3.pageNo + '&pageSize=' + this.page3.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData2 = data.data.records
        this.page4.total = data.data.total
      } else if (this.form.orderType == 3) {
        this.page4.pageNo = 1
        this.currentPage = this.page4.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page4.pageNo + '&pageSize=' + this.page4.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData3 = data.data.records
        this.page3.total = data.data.total
      }
    },
    async cx() {
      this.form.orderType = 4
      let data = await findSupplierMyServiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
      this.tableData = data.data.records
      this.page1.total = data.data.total
    },
    async tableDataList() {
      if (this.tableActiveIndex == 2) {
        let data = await findSupplierMyServiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData2 = data.data.records
        this.page1.total = data.data.total
      } else if (this.tableActiveIndex == 1) {
        let data = await findSupplierMyServiceList('pageNo=' + this.page2.pageNo + '&pageSize=' + this.page2.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData1 = data.data.records
        this.page2.total = data.data.total
      } else if (this.tableActiveIndex == 0) {
        let data = await findSupplierMyServiceList('pageNo=' + this.page4.pageNo + '&pageSize=' + this.page4.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData = data.data.records
        this.page4.total = data.data.total
      } else if (this.tableActiveIndex == 3) {
        let data = await findSupplierMyServiceList('pageNo=' + this.page3.pageNo + '&pageSize=' + this.page3.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData3 = data.data.records
        this.page3.total = data.data.total
      }
    },
    async shuaxin() {
      this.form.year = ""
      this.form.id = ""
      if (this.tableActiveIndex == 2) {
        this.page1.pageNo = 1
        this.currentPage = this.page1.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData2 = data.data.records
        this.page1.total = data.data.total
      } else if (this.tableActiveIndex == 1) {
        this.page2.pageNo = 1
        this.currentPage = this.page2.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page2.pageNo + '&pageSize=' + this.page2.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData1 = data.data.records
        this.page2.total = data.data.total
      } else if (this.tableActiveIndex == 0) {
        this.page3.pageNo = 1
        this.currentPage = this.page3.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page3.pageNo + '&pageSize=' + this.page3.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData = data.data.records
        this.page3.total = data.data.total
      } else if (this.tableActiveIndex == 3) {
        this.page4.pageNo = 1
        this.currentPage = this.page4.pageNo
        let data = await findSupplierMyServiceList('pageNo=' + this.page4.pageNo + '&pageSize=' + this.page4.pageSize + "&orderType=" + this.form.orderType + "&orderNum=" + this.form.id + "&startTime=" + this.form.startTime + "&endTime=" + this.form.endTime);
        this.tableData3 = data.data.records
        this.page4.total = data.data.total
      }

    },
    async applyService(index) {
      this.form2.orderType = this.form.orderType
      if (this.dialogVisible3 == true) {
        this.form2.underlyingAmount = this.filterOut(this.form2.underlyingAmount)
      }
      await applyService(this.form2)
      this.tableDataList()
      // this.$refs.Uploadthereport.clearFiles();
      // this.$refs.form2.validate((valid) => {
      //   if (valid) {
      //     this.dialogVisible = false
      //     this.dialogVisible2 = false
      //     this.dialogVisible3 = false
      //     this.$refs.Uploadthereport.clearFiles();

      //   } else {
      //     return false;
      //   }
      // })
    },
    legalaidpresent(index) {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.applyService()
          this.dialogVisible = false
          this.dialogVisible2 = false
          this.dialogVisible3 = false
          this.$refs['Uploadthereport'].clearFiles()
        } else {
          return false;
        }
      });
    },
    handleSelect(item) {
      this.form2.subjectName = item.company_name
    },
    async getCompanyNameList() {
      if (this.form2.subjectName) {
        let data = await getCompanyNameList('comName=' + this.form2.subjectName)
        console.log(data)
        this.dataname = data.data
        if (this.dataname) {
          this.dataname.forEach((item, index) => {
            this.dataname[index].value = item.company_name
          });
        }
      }
      else {
        this.dataname = []
      }
    },
    querySearch(queryString, cb) {
      this.getCompanyNameList().then(() => {
        var restaurants = this.dataname;
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          // results = queryString ? restaurants : [];
        }
        cb(results);
      })
    },

  }
}
</script>
<style scoped>
::v-deep .special_input .el-select {
  width: 100%;
}

::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
  display: none;
}

::v-deep .exceptArrivalClass .is-success .el-date-editor--date:hover .el-input__prefix {
  display: none;
}

::v-deep .add_invoice_messages .el-input--prefix .el-input__inner {
  padding-left: 10px;
}

::v-deep .retained_profits2_specials .el-input__prefix {
  left: 93% !important;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

::v-deep .el-autocomplete {
  width: 100%;
}

/* >>>.el-dialog .el-input{
                                                      width: 339px;
                                                  } */
>>>.el-table td {
  text-align: center;
}

.anniudiv {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row-reverse;
}

>>>.el-table th {
  padding: 0;
}

>>>.el-dialog {
  width: 500px;
}

.el-dialog .anniudk {
  padding-top: 10px;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
}

>>>.el-upload__tip {
  position: absolute;
  top: 28px;
  right: 100px;
  font-size: 12px;
  transform: scale(0.75);
}

.upload_box i {
  font-size: 20px;
  line-height: 60px;
  color: #7c969b;
}

.el-dialog .qiuxiao {
  width: 160px;
  height: 40px;
  background: #7c969b;
  border-radius: 32px;
  float: left;
  font-size: 14px;
}

>>>.el-table__empty-block {
  min-height: 50px;
  height: 50px !important;
}

>>>.el-table__empty-text {
  line-height: 50px;
}

>>>.el-dialog .el-form-item__content {
  margin-left: 82px !important;
  /* border: 1px solid ; */
}

>>>#wdrz .el-dialog .el-form-item__content {
  margin-left: 96px !important;
}

>>>.el-dialog__body {
  padding: 30px 30px;
}

>>>.mc .el-input__inner {
  /* width: 339px; */
  height: 40px;
  /* padding: 0; */
}

.el-dialog .el-upload--picture-card i {
  font-size: 14px;
  color: #7c969b;
  position: relative;
  bottom: 45px;
  text-align: center;
}

.el-dialog .tishi {
  width: 204px;
  font-size: 12px;
  display: inline-block;
  margin-top: 25px;
}

.el-dialog .tijiao {
  width: 160px;
  height: 40px;
  background: #339c9b;
  border-radius: 32px;
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  border-color: #339c9b;
}

>>>.anniudiv .el-button {
  width: 140px;
  height: 36px;
  line-height: 0px;
  background: #339c9b;
  color: white;
  font-size: 14px;
  border-color: #339c9b;
  /* margin-left: 1280px; */
}

>>>.el-dialog .el-dialog__header {
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #162747;
  line-height: 16px;
  padding-bottom: 0px;
  padding-top: 20px;
}

>>>.el-table__header-wrapper {
  height: 60px;
}

>>>.el-dialog .el-form-item {
  margin-bottom: 20px;
}

.add_invoice_messages>>>.el-input__prefix {
  left: 144%;
}

>>>.el-dialog .el-form-item__label {
  width: 89px !important;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  line-height: 24px;
  /* border: 1px solid ; */
}

>>>#flyz .el-dialog .el-form-item__label {
  width: 72px !important;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #162747;
  line-height: 19px;
}

>>>#wdrz .el-dialog .el-form-item__label {
  width: 86px !important;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #162747;
  line-height: 19px;
}

>>>#jzfw .el-dialog .el-form-item__label {
  width: 72px !important;
  height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #162747;
  line-height: 19px;
}

.upload_box {
  width: 60px;
  height: 60px;
  margin-right: 6px;
  background: #f7fafc;
  border-radius: 4px;
  border: 1px solid #e1e7ea;
}

@media screen and (max-width: 1664px) {
  ::v-deep .el-form-item__error {
    left: 2px;
  }

  ::v-deep .retained_profits2_special .el-input {
    width: 220px !important;
  }

  /* ::v-deep .mc.el-input{
                                                          width: 97% !important;
                                                      }
                                                      ::v-deep .el-date-editor.el-input{
                                                      width: 97%;
                                                  }
                                                      ::v-deep .el-autocomplete{
                                                          width: 97%; */
  /* } */
  >>>.el-upload__tip {
    position: absolute;
    top: 14px;
    right: 19px;
    font-size: 12px;
    transform: scale(0.55);
  }

  .upload_box {
    width: 40px;
    height: 40px;
    margin-right: 2px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  >>>#wdrz .el-dialog .el-form-item__label {
    width: 64px !important;
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    padding: 0px;
  }

  >>>#wdrz .el-radio__label {
    font-size: 12px;
  }

  >>>#jzfw .el-dialog .el-form-item__label {
    width: 52px !important;
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    padding: 0px;
  }

  >>>#flyz .el-dialog .el-form-item__label {
    width: 52px !important;
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    padding: 0px;
  }

  >>>#wdrz .el-dialog .el-form-item__content {
    margin-left: 70px !important;
  }

  .add_invoice_messages>>>.el-input__prefix {
    left: 100%;
  }

  >>>.el-table__empty-block {
    height: 33px !important;
    min-height: 33px;
  }

  >>>.el-table__header-wrapper {
    height: 40px;
  }

  .upload_box i {
    font-size: 14px;
    line-height: 40px;
    color: #7c969b;
  }

  >>>.el-upload-list--picture .el-upload-list__item {
    margin-left: 5px;
  }

  >>>.anniudiv {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
  }

  >>>.el-dialog {
    width: 333px;
  }

  >>>.el-dialog .anniudk {
    padding-top: 6px;
    display: flex;
    margin-left: 26px;
    margin-right: 26px;
  }

  >>>.el-dialog .qiuxiao {
    width: 106px;
    height: 26px;
    background: #7c969b;
    line-height: 0px;
    float: left;
    font-size: 12px;
  }

  >>>.el-dialog .el-form-item__content {
    margin-left: 50px !important;
  }

  >>>.el-dialog__body {
    padding: 20px 20px;
  }

  >>>.mc .el-input__inner {
    /* width: 236px; */
    height: 26px;
    /* padding: 0; */
  }

  >>>#wdrz .mc .el-input__inner {
    /* width: 220px; */
    height: 26px;
    /* padding: 0; */
  }

  >>>.el-dialog .el-upload--picture-card i {
    font-size: 12px;
    color: #7c969b;
    position: relative;
    bottom: 55px;
    text-align: center;
  }

  >>>.el-dialog .tishi {
    width: 206px;
    font-size: 12px;
    display: inline-block;
    zoom: 0.5;
    margin-top: 50px;
  }

  >>>.el-dialog .tijiao {
    width: 106px;
    height: 26px;
    background: #339c9b;
    line-height: 0px;
    margin-left: 26px;
    margin-bottom: 6px;
    font-size: 12px;
    border-color: #339c9b;
  }

  >>>.anniudiv .el-button {
    width: 93px;
    height: 24px;
    line-height: 0px;
    background: #339c9b;
    color: white;
    font-size: 12px;
  }

  >>>.el-dialog .el-dialog__header {
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 10px;
    padding-bottom: 0px;
    padding-top: 13px;
  }

  >>>.el-dialog .el-form-item {
    margin-bottom: 13px;
  }

  >>>.el-dialog .el-form-item__label {
    width: 75px !important;
    height: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    margin-right: 6px;
    transform: scale(calc(10 / 12));
  }

  ::v-deep .el-autocomplete {
    width: 235px;
  }

  >>>.el-dialog .el-input {
    width: 235px;
  }

  ::v-deep .special_input .el-input {
    width: 220px !important;
  }

  ::v-deep .special_input .el-input {
    width: 220px !important;
  }

  ::v-deep .special_input .el-autocomplete {
    width: 220px !important;
  }
}</style>